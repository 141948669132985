import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const BlogSideIcon = ({ blogWebsite }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExpanded(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: "fixed", left: "20px", bottom: "20px" }}>
      {expanded && (
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            marginTop: "10px",
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            textAlign: "center",
          }}
        >
          <a
            href={blogWebsite}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#007bff" }}
          >
            <p style={{ margin: 0 }}>Check out my blog!</p>
            Go to Blog
          </a>
        </motion.div>
      )}
    </div>
  );
};

export default BlogSideIcon;
