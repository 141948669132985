import React, { Suspense, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router } from "react-router-dom";
import HomeContent from "./Components/Constant/HomeContent";
import SeoMetaTags from "./Components/Constant/SeoMetaTags";
import ContentSection from "./Components/ContentSection";
import {
  GoogleAnalytics,
  initializeAnalytics,
} from "./Components/googleAnalytics/analytics";
import ProfileCard from "./Components/Lib/ProfileCard";
import "./Components/Styles/App.css";
import "./Components/Styles/AppOne.css";
import MainLoaderScreen from "./Components/Lib/LoaderScreen/MainScreenLoader";
import WhatsAppModal from "./Components/Lib/Modal/WhatsAppModal";

const AppMain = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {
    mobile,
    name,
    role,
    titleIcon,
    keywords,
    cardImage,
    siteUrl,
    twitterHandle,
  } = HomeContent;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1);

    initializeAnalytics();

    const modalTimer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(modalTimer);
    };
  }, []);

  const handleCloseModal = () => setShowModal(false);

  // const isSmallScreen = useMediaQuery({ maxWidth: 991 });
  const isLargeScreen = useMediaQuery({ minWidth: 992 });

  return (
    <HelmetProvider>
      <SeoMetaTags
        name={name}
        role={role}
        titleIcon={titleIcon}
        keywords={keywords}
        cardImage={cardImage}
        twitterHandle={twitterHandle}
        siteUrl={siteUrl}
      />

      <Router>
        <GoogleAnalytics />
        <div className="appOne-wrapper">
          {/* Navbar and BottomNavbar section */}
          {/* {isLargeScreen && <Navbar />} */}
          {/* {isSmallScreen && <BottomNavbar />} */}
          <div className="container-fluid p-0">
            <div className="row p-0 m-0">
              <div className="col-lg-4 ps-0">
                {isLargeScreen && <ProfileCard />}
              </div>

              <div className="col-lg-8 px-lg-3 py-md-2">
                <div className="split-screen-container pt-md-4">
                  <Suspense>
                    {isLoading ? <MainLoaderScreen /> : <ContentSection />}
                  </Suspense>
                </div>
                {/* Footer section */}
                {/* <Footer /> */}
              </div>
            </div>
          </div>
        </div>
        <WhatsAppModal
          show={showModal}
          handleClose={handleCloseModal}
          mobile={mobile}
        />
      </Router>
    </HelmetProvider>
  );
};

export default AppMain;
