import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBootstrap,
  faCss3Alt,
  faGit,
  faHtml5,
  faJs,
  faNodeJs,
  faReact,
  faWordpressSimple,
  faGithub
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// Add the imported icons to the Font Awesome library
library.add(
  faReact,
  faJs,
  faHtml5,
  faCss3Alt,
  faBootstrap,
  faGit,
  faNodeJs,
  faWordpressSimple,
  faMobileAlt,
  faEnvelope,
  faPhone
);

// Enhanced Home Page Data
const HomePageData = {
  twitterHandle: "@vallarasuk143",
  siteUrl: "https://vallarasuk.com/",
  iconImage: require("../Assets/vallarasuk.jpeg"),
  titleIcon: require("../Assets/logo/favicon-16x16.png"),
  cardImage: require("../Assets/vallarasuk.jpeg"),
  mobileProfileImage: require("../Assets/vallarasuk.jpeg"),
  loadingAnimation: require("../Assets/loading-gifs/profile-loading.gif"),
  mainScreenLoading: require("../Assets/loading-gifs/Jelly-Preloader.webp"),
  resumeDownloadLink: require("../Assets/resume/vallarasuk.pdf"),
  resumeFileName: "Vallarasu K",
  name: "Vallarasu Kanthasamy",
  roles: [
    "React Developer",
    "WordPress Developer",
    "Full-Stack Developer",
    "React Native Developer",
  ],
  bio: "I am Vallarasu K, a passionate software developer with over 1.1 years of experience specializing in React, WordPress custom plugins, and full-stack development using Node.js and Express.js. I focus on building engaging and user-friendly web applications.",
  email: "contact@vallarasuk.com",
  emailTo: "mailto:contact@vallarasuk.com",
  mobile: "8122992143",
  mobileTo: "tel:8122992143",
  instagramProfileLink: "https://www.instagram.com/vallarasu_kanthasamy/?hl=en",
  twitterProfileLink: "https://twitter.com/vallarasuk143",
  linkedInProfileLink: "https://www.linkedin.com/in/vallarasu-k/",
  githubProfileLink: "https://github.com/vallarasuk",
  blogWebsite:"https://blog.vallarasuk.com",
  title: "Explore My Web Universe",
  description: [
    "Hello! I'm Vallarasu K, a dedicated React Developer focused on delivering modern web solutions using the latest technologies.",
    "With over a year of practical experience, I specialize in React, WordPress custom development, and full-stack solutions using Node.js and Express.js. My portfolio showcases diverse projects that reflect my commitment to quality and user engagement.",
    "From responsive landing pages to robust web applications, I enjoy exploring the latest trends in web development to create impactful digital experiences.",
    "I invite you to explore my work and connect with me for collaboration opportunities. Your visit is valuable, and I look forward to creating something extraordinary together!",
  ],
  projectButtonName: "View My Projects",
  projectButtonlink: "/projects",
  skillsTitle: "My Skills and Expertise",
  skills: [
    { name: "React", icon: faReact },
    { name: "JavaScript", icon: faJs },
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "Bootstrap", icon: faBootstrap },
    { name: "Git", icon: faGit },
    { name: "Node.js", icon: faNodeJs },
    { name: "WordPress", icon: faWordpressSimple },
    { name: "Responsive Web Design", icon: faMobileAlt },
    { name: "React Native", icon: faReact },  
    { name: "GitHub", icon: faGit }, 
  ],
  
  projectsData: [
    {
      title: "Setup and Training",
      description:
        "Developed and deployed a modern training setup using cutting-edge web technologies.",
      livePreview:
        "https://www.fondriest.com/field-station/set-up-and-training/",
      previewImage: require("../Assets/image.png"),
    },
    {
      title: "Home Page",
      description:
        "Designed a responsive and interactive homepage prioritizing user experience.",
      livePreview: "https://www.fondriest.com/field-station/",
      previewImage: require("../Assets/fieldstation-Homepage.png"),
    },
    {
      title: "Education Outreach",
      description:
        "Created an educational outreach platform to foster community engagement.",
      livePreview:
        "https://www.fondriest.com/field-station/educational-outreach/",
      previewImage: require("../Assets/education-outreach.png"),
    },
    {
      title: "Otto Clone",
      description:
        "Cloned and customized the Otto landing page using React, showcasing modern UI practices.",
      gitLink: "https://github.com/vallarasuk/otto-landing-page",
      livePreview: "https://vallarasuk.github.io/otto-landing-page/",
      previewImage: require("../Assets/otto landing.png"),
    },
    {
      title: "Macro Touch Clone",
      description:
        "Replicated the Macro Touch interface with a strong emphasis on responsive design.",
      gitLink: "https://github.com/vallarasuk/Macro-Touch",
      livePreview: "https://vallarasuk.github.io/Macro-Touch/",
      previewImage: require("../Assets/macroTouch.png"),
    },
    {
      title: "About Internet Blog",
      description:
        "Developed a blog platform that shares insights on front-end development and best practices.",
      gitLink: "https://github.com/vallarasuk/Front-End-Blog",
      livePreview: "https://vallarasuk.github.io/Front-End-Blog/",
      previewImage: require("../Assets/about internet blog.png"),
    },
    {
      title: "Portfolio",
      description:
        "Created a personal portfolio to showcase my skills, projects, and experiences.",
      livePreview: "https://vallarasuk.w3spaces.com/",
      previewImage: require("../Assets/portfolio 2.png"),
    },
    {
      title: "Nexsens CB 250",
      description:
        "Developed a product page for the Nexsens CB 250 data buoy, enhancing user interaction.",
      livePreview: "https://www.nexsens.com/products/data-buoys/cb-250",
      previewImage: require("../Assets/cb 250 page.png"),
    },
    {
      title: "Nexsens X2 Environmental",
      description:
        "Built a comprehensive product page for the Nexsens X2 environmental data logger.",
      livePreview:
        "https://www.nexsens.com/products/data-loggers/x2-environmental-data-logger",
      previewImage: require("../Assets/Nexsens X2 page.png"),
    },
    {
      title: "Nexsens Contact Us",
      description:
        "Designed a user-friendly contact page to improve customer communication.",
      livePreview: "https://www.nexsens.com/support/contact-us",
      previewImage: require("../Assets/Nexsens Contact us page.png"),
    },
    {
      title: "Nexsens YSI",
      description:
        "Developed a product page for the YSI EXO3 multi-parameter water quality sonde, enhancing product visibility.",
      livePreview:
        "https://www.nexsens.com/products/sensors/water-quality/ysi-exo3-multi-parameter-water-quality-sonde",
      previewImage: require("../Assets/YSI page.png"),
    },
  ],
  studies: [
    {
      year: "2017 - 2020",
      title: "Diploma in Mechanical Engineering",
      institution: "Government Polytechnic College",
      description:
        "Acquired a foundational understanding of engineering principles, which sparked my interest in technology.",
      icon: "graduation",
    },
    {
      year: "2016 - 2017",
      title: "Secondary School Leaving Certificate (SSLC)",
      institution: "National Matriculation School",
      description:
        "Focused on computer science and mathematics, enhancing my analytical skills.",
      icon: "school",
    },
  ],
  aboutTitle: "Hello, I'm Vallarasu K",
  aboutDescription:
    "As a dedicated software developer, I am committed to crafting exceptional web experiences. I achieved an impressive 87% in my SSLC and pursued a Diploma in Mechanical Engineering, which ignited my passion for front-end development. Currently, I work as a Software Developer at Thidiff Technology in Bangalore, utilizing my skills in React, WordPress, Node.js, and Express.js to create impactful digital solutions.",
  keywords: [
    "Vallarasu K",
    "React Developer",
    "WordPress Developer",
    "Full-Stack Developer",
    "Software Developer in Bangalore",
    "Node.js Developer",
    "Express.js Developer",
    "React Native Developer",
    "Front-End Development",
    "JavaScript Development",
    "Freelance React Developer",
    "Hire React Developer",
    "Bangalore Web Developer",
    "Custom WordPress Solutions",
    "Responsive Web Design",
    "Dynamic Web Applications",
    "Educational Outreach Platform",
    "Personal Portfolio",
    "Software Development Services",
    "Web Application Development",
    "Bangalore Software Developer",
    "JavaScript Frameworks",
    "Front-End Technologies",
    "Modern Web Development",
    "UI/UX Design",
    "Freelance WordPress Developer",
    "E-commerce Web Development",
    "Interactive Web Applications",
    "Custom Theme Development for WordPress",
    "Software Developer in Bangalore",
    "Expert JavaScript Development Services",
    // Additional high-intent keywords
    "Remote React Developer",
    "Top React Developer in Bangalore",
    "Affordable Web Development Services Bangalore",
  ],
};

export default HomePageData;
